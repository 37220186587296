import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
    <Layout>
        <SEO title="Contacto" />
        <div className="inner-banner">
            <div className="container">
                <div className="inner-title text-center">
                    <h3>Hablemos</h3>
                    <ul>
                        <li>
                            <a href="index.html">Inicio</a>
                        </li>
                        <li>
                            <i className="bx bx-chevron-right"></i>
                        </li>
                        <li>Contacto</li>
                    </ul>
                </div>
            </div>
            <div className="inner-banner-shape">
                <div className="shape-one">
                    <StaticImage src="../images/inner-banner/banner-shape1.png" alt="Images" />
                </div>
                <div className="shape-two">
                    <StaticImage src="../images/inner-banner/banner-shape2.png" alt="Images" />
                </div>
                <div className="shape-three">
                    <StaticImage src="../images/inner-banner/banner-shape3.png" alt="Images" />
                </div>
                <div className="inner-banner-dots">
                    <StaticImage src="../images/shape/dots-shape.png" alt="Images" />
                </div>
            </div>
        </div>
        <div className="contact-area pb-70">
            <div className="container">
                <div className="section-title text-center">
                    <span className="sp-before sp-after">Contacto</span>
                    <h2>¿Tienes un proyecto? </h2>
                </div>
                <div className="row pt-45">
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-card">
                            <i className="flaticon-planet-earth"></i>
                            <h3>Ubicación</h3>
                            <p>Para correspondencia: Cra 58C No. 142 20</p>
                            <p> Bogotá, Colombia</p>
                            <a href="#" className="contact-card-btn">
                            ¡Ahora el equipo es full remoto!
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-card">
                            <i className="flaticon-email"></i>
                            <h3>Contacto</h3>
                            <p><a href="mailto:info@ugni.io" className="__cf_email__">info@ugni.io</a>
                            </p>
                            <p> Teléfono: (+57) - 3012277864 </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div className="contact-card">
                            <i className="flaticon-clock"></i>
                            <h3>Horario de atención</h3>
                            <p>Lunes - Jueves: 09:00 - 20:00</p>
                            <p>Viernes: 10:30 - 20:00</p>
                            <a href="https://support.ugni.io/portal/es/home" className="contact-card-btn">
                            Soporte
                                <i className="bx bx-plus plus-btn"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contact-section pt-100 pb-70">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="contact-img">
                            <StaticImage src="../images/contact-img.png" alt="Images" />
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="contact-wrap">
                            <div className="contact-form">
                                <div className="section-title">
                                    <span className="sp-before sp-after">Contacto</span>
                                    <h2>Hablemos</h2>
                                </div>
                                <form id="contactForm" data-netlify="true">
                                    <input type="hidden" name="form-name" value="contact" />
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="form-group">
                                                <i className="bx bx-user"></i>
                                                <input type="text" name="name" id="name" className="form-control" required
                                                    data-error="Por favor, ingresa tu nombre" placeholder="Nombre*" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="form-group">
                                                <i className="bx bx-user"></i>
                                                <input type="email" name="email" id="email" className="form-control" required
                                                    data-error="Por favor, ingresa tu email" placeholder="Correo electrónico*" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="form-group">
                                                <i className="bx bx-phone"></i>
                                                <input type="text" name="phone_number" id="phone_number" required
                                                    data-error="Por favor, ingresa un teléfono" className="form-control"
                                                    placeholder="Teléfono" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="form-group">
                                                <i className="bx bx-file"></i>
                                                <input type="text" name="msg_subject" id="msg_subject" className="form-control"
                                                    required data-error="Ingresa un asunto"
                                                    placeholder="Asunto" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <i className="bx bx-envelope"></i>
                                                <textarea name="message" className="form-control" id="message" cols="30"
                                                    rows="8" required data-error="Ingresa un mensaje"
                                                    placeholder="Mensaje"></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="default-btn border-radius">
                                            Enviar
                                                <i className="bx bx-plus"></i>
                                            </button>
                                            <div id="msgSubmit" className="h3 text-center hidden"></div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contact-map">
            <div className="container-fluid m-0 p-0">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2215.5317448024143!2d-74.06822944209455!3d4.729208673859422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5053a073b8c4d0dc!2sMultifamiliares%20Acr%C3%B3polis!5e0!3m2!1ses-419!2sco!4v1616513731897!5m2!1ses-419!2sco"
                    width="600" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false"
                    tabIndex="0"></iframe> </div>
        </div>
    </Layout>
)

export default ContactPage
